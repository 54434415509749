<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Form-element component
 */
export default {
  page: {
    title: "Forms Elements",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Users",
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      password: null,
      rePassword: null,
      items: [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Users",
          active: true
        }
      ],
      status: "not_accepted",
      checkCustom: "not_accepted",
      checked: true
    };
  },

  methods: {
    register() {
      if (this.password === this.rePassword) {
        this.$store.dispatch('auth/register', { firstName: this.firstName, lastName: this.lastName, email: this.email, phoneNumber: this.phoneNumber, password: this.password }).then((res) => {
          console.log(res)
          this.$bvToast.toast('User Created', {
            variant: 'success',
            solid: true
          })
        }).catch((err)=>{
          console.log(err)
          this.$bvToast.toast('User already exist', {
          variant: 'danger',
          solid: true
        })
        })

      }
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Create User</h4>


            <div class="row mt-5">
              <div class="col-12">
                <form class="form-horizontal" role="form" @submit.prevent="register">
                  <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="First Name" label-for="text">
                    <b-form-input for="text" type="text" value="" v-model="firstName"></b-form-input>
                  </b-form-group>

                  <b-form-group id="example text" label-cols-sm="2" label-cols-lg="2" label="Last Name" label-for="text">
                    <b-form-input for="text" type="text" value="" v-model="lastName"></b-form-input>
                  </b-form-group>


                  <b-form-group id="example-email" label-cols-sm="2" label-cols-lg="2" label="Email" label-for="email">
                    <b-form-input id="email" type="email" value="" v-model="email"></b-form-input>
                  </b-form-group>

                  <b-form-group id="example-tel" label-cols-sm="2" label-cols-lg="2" label="Phone Number"
                    label-for="tele">
                    <b-form-input id="tele" value="" type="tel" name="tel" v-model="phoneNumber"></b-form-input>
                  </b-form-group>

                  <b-form-group id="example-email" label-cols-sm="2" label-cols-lg="2" label="Password"
                    label-for="password">
                    <b-form-input id="password" type="password" value="" v-model="password"></b-form-input>
                  </b-form-group>

                  <b-form-group id="example-email" label-cols-sm="2" label-cols-lg="2" label="Re-Password"
                    label-for="password">
                    <b-form-input id="re-password" type="password" value="" v-model="rePassword"></b-form-input>
                    <p class="text-danger" v-if="password !== rePassword && rePassword !== null">Password not matched</p>
                  </b-form-group>






                  <div style="text-align: center">
                    <b-button variant="primary" type="submit">Create User</b-button>
                  </div>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->



    <!-- end row -->
  </Layout>
</template>